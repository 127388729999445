import React from 'react';

import CoinIcon from 'components/CoinIcon'

import { isNil } from 'lodash'

function PageHeader({ pageTitle, coinBalance, userId }) {

  return (
    <header className='page-header'>
      <div className='user-info'>
        <span className='logo'>R E H U M A N</span>
        <h1>{pageTitle}</h1>
      </div>

      {!isNil(coinBalance) && (
        <a href={`/my/transactions`} className='coin-balance'>
          <CoinIcon text={coinBalance} />
          Recoins
        </a>
      )}
    </header>
  )
}

export default PageHeader
