import React, { Fragment, useState } from 'react';
import { map, isNil } from 'lodash';

import PageHeader from 'components/PageHeader'
import ChallengeCard from 'components/ChallengeCard'
import Questionnaire from 'components/Questionnaire'

import Stopwatch from 'images/stopwatch.png'
import Broccoli from 'images/broccoli.png'
import Medal from 'images/medal.png'
import Rectangle from 'images/Rectangle.svg'
import StepChallengeCard from './StepChallengeCard';

const HeroImage = ({ title, subtitle }) => {
  return (
    <div className="dashboard-hero-image" style={{ backgroundImage: `url(${Rectangle})` }}>
      <div className="dashboard-hero-text">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

function Dashboard({ currentUser, dailyChallenges, todaysStepChallenge, todaysQuestionnaire }) {
  const [challenges, setChallenges] = useState(dailyChallenges)
  const [coinBalance, setCoinBalance] = useState(currentUser.coin_balance)
  const [showQuestionnaire, toggleQuestionnaire] = useState(false)
  const [isQuestionnaireCompleted, completeQuestionnaire] = useState(todaysQuestionnaire.isCompleted)

  return (
    <Fragment>
      <PageHeader coinBalance={coinBalance} />
      <HeroImage title={`Welcome back, ${currentUser.firstName}!`} />

      {!isNil(todaysQuestionnaire) && (
        <>
          <div className='card questionnaire-waiting-card'>
            {isQuestionnaireCompleted ? (
              <>
                <h3 className='questionnaire-waiting-card-title'>
                  Questionnaire complete!
                </h3>
                <p className='questionnaire-waiting-card-text'>
                  <br />Your new coin balance will be updated the next day.
                  <br />Check back in tomorrow.
                </p>
              </>
            ) : (
                <>
                  <h3 className='questionnaire-waiting-card-title'>You have an unanswered questionnaire</h3>

                  <button onClick={() => toggleQuestionnaire(true)}>Would you like to complete it?</button>

                  <Questionnaire completeQuestionnaire={completeQuestionnaire} showQuestionnaire={showQuestionnaire} toggleQuestionnaire={toggleQuestionnaire} questionnaire={todaysQuestionnaire} />
                </>
              )}
          </div>
        </>
      )}

      <main>
        <div className='action-buttons'>
          <div className='action-button-card'>
            <img src={Broccoli} />
            Plan
            <p className='action-button-card-subtitle'>Goals</p>
          </div>

          <div className='action-button-card'>
            <img src={Stopwatch} />
            Track
            <p className='action-button-card-subtitle'>Progress</p>
          </div>

          <div className='action-button-card'>
            <img src={Medal} />
            Coach
            <p className='action-button-card-subtitle'>Recoach</p>
          </div>
        </div>

        <h2>Your Plan: Health Challenges today</h2>
        {todaysStepChallenge && <StepChallengeCard challenge={todaysStepChallenge} />}
        <div className='challenge-cards'>
          {map(challenges, (challenge) => 
            <ChallengeCard 
              setCoinBalance={setCoinBalance} 
              challenge={challenge} 
              setChallenges={setChallenges} 
            />
          )}
        </div>
      </main>
    </Fragment>
  )
}

export default Dashboard
