import React, { Fragment, useState } from 'react';
import { map } from 'lodash';

import PageHeader from 'components/PageHeader'
import CoinIcon from 'components/CoinIcon'


function parseTransactionDate(date) {
  const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions()

  return new Date(date).toLocaleString(locale, { timeZone, dateStyle: 'short', timeStyle: 'short' })

}
function CoinTransactions({ currentUser, coinTransactions }) {
  return (
    <>
      <PageHeader pageTitle='Recoin Transactions' />

      <div className='balance-card card'>
        <div>
          <h2>Your balance</h2>

          <div className='balance-card-coin-pill'>
            <CoinIcon text='R' />
            <span className='balance-card-coin-pill-amount'>{currentUser.coin_balance}</span>
            <span>{' + this month'}</span>

          </div>
        </div>

        <div className='coin-balance'>
          <CoinIcon text={currentUser.coin_balance} />
          Recoins
        </div>
      </div>

      <h2>Transaction History</h2>
      <ul className='transaction-log'>
        {map(coinTransactions, transaction => (
          <li className='transaction-log-item'>
            <CoinIcon text={`${transaction.balance > 0 ? '+' : '-'}${transaction.balance}`} />
            <div className='transaction-log-item-content'>
              <p className='transaction-log-item-title'>
                {transaction.title} <em className='transaction-log-item-date'>{parseTransactionDate(transaction.created_at)}</em>
              </p>

              {transaction.comment && <i>{transaction.comment}</i>}
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}
export default CoinTransactions;
