import React, { useState } from 'react';
import axios from 'axios';
import { map, isNil, some } from 'lodash';

import Question from 'components/Questionnaire/Question';

function submit({ formState, completeQuestionnaire, toggleQuestionnaire, setErrors }) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  const questions = map(formState, (v, questionId) => ({ question_id: questionId, answer: v })) 

  axios.post('questionnaire_responses', { questionnaire_responses: questions, authenticity_token: csrfToken }).then(() => {
    completeQuestionnaire(true)
    toggleQuestionnaire(false)
  }).catch((response) => {
      setErrors(["There was an issue sending your response."])
  })
}

function setFormState(questionnaire) {
  let state = {}
  questionnaire.questions.forEach((question) => {
    state[question.id] = null

    if (question.answerType === 'boolean') { state[question.id] = false }
  })

  return state
}

function Questionnaire({ completeQuestionnaire, toggleQuestionnaire, showQuestionnaire, questionnaire }) {
  if (!showQuestionnaire) { return null }
  const [formState, updateFormState] = useState(setFormState(questionnaire))
  const [errors, setErrors] = useState(null)

  return (
    <div className='questionnaire-modal card'>
      <p onClick={() => toggleQuestionnaire(false)}>Close</p>

      {map(questionnaire.questions, question =>  <Question formState={formState} question={question} updateFormState={updateFormState} />)}


      {some(errors) && ( 
        <div className='alert alert--error'>
          <ul>
            {map(errors, error => <li>{error}</li>)}
          </ul>
        </div>
      )}
      <div className='questionnaire-button-container'>
        <button onClick={() => toggleQuestionnaire(false)} className='button--outline'>Cancel</button>
        <button onClick={() => submit({ formState, completeQuestionnaire, toggleQuestionnaire, setErrors })}>Submit Questionnaire</button>
      </div>
    </div>
  )
}

export default Questionnaire;
