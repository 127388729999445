import React from 'react';
import { isNil } from 'lodash';

import RatingInput from 'components/Questionnaire/RatingInput'

function ResponseComponent({ questionId, type, label, isRange, formState, updateFormState }) {
  switch(type) {
    case 'number': 
      if (isRange) {
        return <RatingInput selectedValue={formState[questionId]} onChange={e => updateFormState(prevState => ({ ...prevState, [questionId]: e.target.value }))} />
      } else {
        return <div><input type='number' onChange={e => updateFormState(prevState => ({ ...prevState, [questionId]: e.target.value }))} /></div>
      }
      break;
    case 'text': 
      return <div><input  onChange={e => updateFormState(prevState => ({ ...prevState, [questionId]: e.target.value }))} /></div>
      break;
    case 'boolean': 
      return (
        <label className='questionnaire-checkbox'>
          <input type="checkbox" onChange={e => { updateFormState(prevState => ({ ...prevState, [questionId]: e.target.checked })) }} />
          {label}
        </label>
      )
      break;
  }
}

function Question({ question, updateFormState, formState }) {
  let label = question.title
  let subtitle

  if (question.answerType === 'number' && !isNil(question.minAnswer)) {
    const splitLabel = question.title.split('(')

    label = splitLabel[0]
    subtitle = splitLabel[1]
  }

  return (
    <div className='card questionnaire-question-card'>
      {question.answerType !== 'boolean' && (
        <h4 className='questionnaire-question-card-title'>
          {label}

          {!isNil(subtitle) && <p>({subtitle}</p>}
        </h4>
      )}

      <ResponseComponent questionId={question.id} formState={formState} isRange={!isNil(question.minAnswer)} updateFormState={updateFormState} type={question.answerType} label={question.title} />
    </div>
  )
}

export default Question;
