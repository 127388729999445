import React, { useState } from 'react';
import { map } from 'lodash';
import axios from 'axios';

import CoinIcon from 'components/CoinIcon'
import Clock from 'images/clock.svg'

async function submitChallenge({ setChallenges, challengeId, challengeNotes, satisfactionScore, setCoinBalance, stepCountImage }) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    const response = await axios.patch(`users_challenges/${challengeId}`, 
    { notes: challengeNotes, satisfaction_score: satisfactionScore, authenticity_token: csrfToken}
    )
    setChallenges(prevChallenges => 
      map(prevChallenges, (challenge) => {
        if (challenge.id === challengeId) { 
          challenge.completed = true 
          setCoinBalance(prevBalance => prevBalance + challenge.coin_amount)
        }

        return challenge
      })
    )
}

function ChallengeType({ challenge }) {
  if (challenge.challenge_type == 'Timed') {
    return (
      <>
        <img src={Clock} />
        {challenge.duration}
      </>
    )
  }

  return challenge.challenge_type
}

const CompletingChallenge = ({ setChallenges, challenge, challengeNotes, satisfactionScore, setCoinBalance, startCompletion, setChallengeNotes }) => {
  return(
  <li className='challenge-card-complete'>
    <label>
      Notes
      <br />
      <textarea className='challenge-card-challenge-notes' onChange={(e) => setChallengeNotes(e.target.value) } />
    </label>
    <p>
      <span style={{ marginRight: '5px' }} onClick={() => submitChallenge({ setChallenges, challengeId: challenge.id, setCoinBalance, challengeNotes, satisfactionScore })}>Confirm</span>
      <span onClick={() => startCompletion(false)}>Cancel</span>
    </p>
  </li>
)
}

const CompletedSection = ({ challenge, completingChallenge, setChallenges, setCoinBalance, challengeNotes, satisfactionScore, startCompletion, setChallengeNotes }) => {
  if (challenge.completed && challenge.challenge_type != 'Step') {
    return(
      <li className='challenge-card-completed'>Completed</li>  
    )
  }
  
  if (completingChallenge) {
    return(<CompletingChallenge setChallenges={setChallenges} challenge={challenge} challengeNotes={challengeNotes} satisfactionScore={satisfactionScore} setCoinBalance={setCoinBalance} startCompletion={startCompletion} setChallengeNotes={setChallengeNotes} />)
  }

  return(
    <button className='button--link challenge-card-complete' onClick={() => startCompletion(true)}>Complete Challenge</button>
  )
}

function ChallengeCard({ challenge, setChallenges, setCoinBalance }) {
  const [completingChallenge, startCompletion] = useState(false)
  const [challengeNotes, setChallengeNotes] = useState('')
  const [satisfactionScore, setSatisfactionScore] = useState(null)

  return (
    <div className='card challenge-card'>
      <ul>
        <li className='challenge-card-title'>{challenge.title}</li>
        {challenge.challenge_type && (
          <li className='challenge-card-duration'><ChallengeType challenge={challenge} /></li>
        )}

        <li className='challenge-card-coin-amount'>
          <div className='challenge-card-coin'>
            <CoinIcon text='R' small />
          </div>

          <span>{challenge.coin_amount}</span>
        </li>
        <CompletedSection
          challenge={challenge}
          completingChallenge={completingChallenge}
          setChallenges={setChallenges}
          setCoinBalance={setCoinBalance}
          challengeNotes={challengeNotes}
          satisfactionScore={satisfactionScore}
          startCompletion={startCompletion}
          setChallengeNotes={setChallengeNotes}
          setSatisfactionScore={setSatisfactionScore}
        />
      </ul>
    </div>
  )
}

export default ChallengeCard
