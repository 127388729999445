import axios from "axios"
import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import CoinIcon from 'components/CoinIcon'

const styles = {
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  zeroMargin: {
    margin: '0px'
  },
  coinIcon: {
    width: '30px',
    height: '30px'
  },
  dropzoneContainer: {
    border: '1px dashed #fff',
    borderRadius: '5px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}

const uploadImage = async (image, challengeId, setStepCountImage) => {
  let data = new FormData();
  data.append('step_count_image', image, image.name);

  try {

    axios.patch(`users_challenges/${challengeId}`, data, {
      headers: {
        'Content-Type': `multipart/form-data`,
      }
    })
    alert('Image uploaded successfully. Once it has been reviewed you will recieve your recoin');
    setStepCountImage(null)
  } catch (error) {
    alert(error.message)
  }
}

const ShowImage = ({ image, challenge, setStepCountImage }) => {
  return(
    <div>
      <p>{image.name}</p>
      <button onClick={() => uploadImage(image, challenge.id, setStepCountImage)}>
        Upload Image
      </button>
    </div>
  )
}

const StepChallengeCard = ({ challenge }) => {
  const [stepCountImage, setStepCountImage] = useState(null)
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: acceptedFiles => setStepCountImage(acceptedFiles[0])})
  if (challenge.completed) {
    return(
      <div style={styles.flex}>
      <div className='card' style={styles.container}>
        <div style={{...styles.flex, flexDirection: 'row', justifyContent: 'space-between'}}>
          <h3 style={styles.zeroMargin}>{challenge.title}</h3>
          <div style={styles.coinIcon}>
            <CoinIcon text={challenge.coin_amount}/>
          </div>
        </div>
        <p>You have completed this challenge. Please come back tomorrow to earn more rewards</p>
      </div>
    </div>
    )
  }
  return (

    <div style={styles.flex}>
      <div className='card' style={styles.container}>
        <div style={{...styles.flex, flexDirection: 'row', justifyContent: 'space-between'}}>
          <h3 style={styles.zeroMargin}>{challenge.title}</h3>
          <div style={styles.coinIcon}>
            <CoinIcon text={challenge.coin_amount}/>
          </div>
        </div>
        <p>Upload a screenshot of your step count to earn recoin</p>
        {
          stepCountImage ? <ShowImage image={stepCountImage} challenge={challenge} setStepCountImage={setStepCountImage} /> :
          <div {...getRootProps()} style={styles.dropzoneContainer}>
            <input {...getInputProps()} />
              <span style={{ marginTop: '10px', marginBottom: '10px'}}>
                Click to upload
              </span>
          </div>
        }
      </div>
    </div>
    )
}

export default StepChallengeCard
