import React from 'react';

import Coin from 'images/coin.svg';

import { isNil } from 'lodash'

function CoinIcon({ text, small = false }) {
  let className = 'coin-icon'
  if (small) { className += ' coin-icon--small' }

  return (
    <div className={className}>
      <img src={Coin} />
      {!isNil(text) && <span className='coin-icon-text'>{text}</span>}
    </div>
  )
}

export default CoinIcon;
