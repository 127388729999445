import React, { useState } from 'react';

const RatingInput = ({ selectedValue, onChange }) => {
  return (
    <div>
      <div>
        {[1, 2, 3, 4, 5].map((value) => (
          <label style={{ marginRight: '20px' }} key={value}>
            <input
              type="radio"
              value={value}
              checked={String(selectedValue) === String(value)}
              onChange={onChange}
            />
            {value}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RatingInput;
