import React from 'react';
import { map } from 'lodash';
import axios from 'axios';

import PageHeader from 'components/PageHeader'


function updateUserFocus(focus) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  axios.patch('/my/focus', { user_focus: focus, authenticity_token: csrfToken }).then(() => window.location.href = '/')
}

function UsersFocusForm({ currentUser }) {
  return (
    <>
      <PageHeader pageTitle={`Welcome, ${currentUser.firstName}!`} coinBalance={currentUser.coin_balance} />

      <div className='users-focus-container'>
        <h2>What are your priorities?</h2>

        <div className="action-buttons action-buttons--2x2">
          {map(['Sleep', 'Nutrition', 'Mental Health', 'Physical Health'], (focus) => (
            <div key={focus.split(' '[0])} onClick={() => updateUserFocus(focus)} className="action-button-card action-button-card--short action-button-card--wide">
              {focus}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UsersFocusForm;
