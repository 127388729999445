// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import React from 'react';
import { createRoot } from 'react-dom/client'
import _ from 'lodash'
import Dashboard from 'components/Dashboard.jsx'
import UsersFocusForm from 'components/Users/FocusForm.jsx'
import CoinTransactions from 'components/Users/Transactions.jsx'

const initComponent = (selector, component) => {
  _.each(document.querySelectorAll(selector), (container) => {
    let data = {}
    if (typeof container.dataset.props !== 'undefined') {
      data = JSON.parse(container.dataset.props)
  }
    const reactComponent = React.createElement(component, data)
    const element = createRoot(container);
    element.render(reactComponent)
  })
}

window.addEventListener('load', () => {
  initComponent('.js-react-Dashboard', Dashboard)
  initComponent('.js-react-UsersFocusForm', UsersFocusForm)
  initComponent('.js-react-CoinTransactions', CoinTransactions)
})

